@import "variables";

$_bg: #d4d3d3;
$_color: #fff;
$_bg-active: accent-palette(500);
$_color-aclive: accent-contrast(500);
$_bg-disabled: rgba($_bg, 0.6);
$_color-disabled: $_color;
$_module-rem: $module-rem;
$_shape: 500px;
$_animation: $animation;

.ant-table-wrapper {
  overflow-x: hidden;

  .ant-table {
    overflow: auto;

    tr {
      td,
      th {
        padding: $module-rem ($module-rem * 1.5);

        &:first-child {
          padding-left: $module-rem * 3;
        }
        &:last-child {
          padding-right: $module-rem * 3;
        }
      }
      &:hover:not(.ant-table-expanded-row) {
        > td {
          background-color: rgba(main-palette(500), 0.06);
        }
      }
    }

    .ant-table-expanded-row {
      overflow: hidden;
      table tr th {
        border-radius: 0;
      }
      td + td {
        overflow: hidden;
        padding-top: $module-rem * 1.6;
        padding-bottom: $module-rem * 1.6;
      }
    }
  }
  .ant-pagination {
    float: left;
    padding-left: $module-rem * 3;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      align-items: center;
      background: $_bg;
      border-radius: $_shape;
      border: none;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      height: $module-rem * 3;
      min-width: $module-rem * 3;
      width: $module-rem * 3;
      text-align: center;
      text-decoration: none;
      transition: background-color 0.2s $_animation, color 0.2s $_animation;
  
      .ant-pagination-item-link {
        background: transparent;
        border: none !important;
        color: #fff;
      }
      a {
        color: #fff;
      }

      &:hover,
      &.ant-pagination-item-active {
        background: $_bg-active;
        color: $_color-aclive;
      }
      &.ant-pagination-disabled {
        background: $_bg-disabled;
        color: $_color-disabled;
        pointer-events: none;
      }
    }
  }
  .ant-table-thead {
    tr {
      > th.ant-table-column-has-actions.ant-table-column-has-sorters {
        &:hover {
          background: unset;
        }
        &:active {
          .anticon {
            color: unset !important;
          }
        }

        .ant-table-column-sorters {
          display: inline-flex;

          .ant-table-column-sorter {
            order: -1;
            display: inline-flex;
            align-items: baseline;

            .ant-table-column-sorter-inner {
              margin: 0 $module-rem 0 0;

              .ant-table-column-sorter-up,
              .ant-table-column-sorter-down {
                color: rgba(main-palette(500), .5);

                &.on {
                  color: main-palette(500);
                }
              }
            }
          }
        }
      }
    }
    .ant-table-row {
      color: rgba(main-palette(500), 0.8);

      > th {
        background-color: rgba(main-palette(500), 0.1);
        color: inherit;
        padding: 0 ($module-rem * 1.6);
        height: 40px;
      }

      .ant-table-has-actions.ant-table-has-sorters {
        &:hover {
          background: rgba(main-palette(500), 0.3);
        }
      }
    }
  }
  .ant-table-tbody {
    > tr {
      td,
      th {
        padding: $module-rem ($module-rem * 1.5);

        &:first-child {
          padding-left: $module-rem * 3;
        }
        &:last-child {
          padding-right: $module-rem * 3;
        }
      }
      &:hover:not(.ant-table-expanded-row) {
        > td {
          background-color: rgba(main-palette(500), 0.06);
        }
      }
    }
  }
}

.ant-table-column-title {
  font-weight: 700 !important;
}

.item-list {
  table {
    padding: 0 2px;
    border-collapse: separate;
    border-spacing: 0 $module-rem;

    thead {
      tr {
        background: transparent;
        box-shadow: none;
        th {
          box-shadow: none;
          background: transparent !important;
          border: none;
        }
      }
    }
    tbody {
      tr {
        border-radius: $shape;
        box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);

        &:hover {
          td {
            background: #fff;
          }
        }

        td {
          background: #fff;
          margin-top: $_module-rem / 2;
          margin-bottom: $_module-rem / 2;

          &:first-child {
            border-top-left-radius: $shape;
            border-bottom-left-radius: $shape;
          }
          &:last-child {
            border-top-right-radius: $shape;
            border-bottom-right-radius: $shape;
          }
        }
      }
    }
  }
}
