@import 'variables';

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-overlay {
  background: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.main-loader {
  display: block;
  height: 64px;
  left: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  top: 50%;
  width: 64px;

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;

    &:after {
      background: #9d5be2;
      border-radius: 50%;
      content: " ";
      display: block;
      height: 6px;
      margin: -3px 0 0 -3px;
      position: absolute;
      width: 6px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;

      &:after {
        left: 50px;
        top: 50px;
      }
    }

    &:nth-child(2) {
      animation-delay: -0.072s;

      &:after {
        background: #a45aca;
        left: 45px;
        top: 54px;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.108s;

      &:after {
        background: #ac59b1;
        left: 39px;
        top: 57px;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.144s;

      &:after {
        background: #b158a0;
        left: 32px;
        top: 58px;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.18s;

      &:after {
        background: #b65792;
        left: 25px;
        top: 57px;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.216s;

      &:after {
        background: #c05670;
        left: 19px;
        top: 54px;
      }
    }
    &:nth-child(7) {
      animation-delay: -0.252s;

      &:after {
        background: #c6555c;
        left: 14px;
        top: 50px;
      }
    }
    &:nth-child(8) {
      animation-delay: -0.288s;

      &:after {
        background: #cc5448;
        left: 10px;
        top: 45px;
      }
    }
  }
}