.app-version {
    color: white;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100px;
    margin-bottom: -50px;
    align-items: end;
    font-weight: 300;
}
.dropdown-content-wrap {
    z-index: 9999 !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.wd-400 {
    width: 400px !important;
}
.img-input {
    display: flex;
    justify-content: space-between;
}
.validation-img {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
}
.server-content {
    margin-top: 50px;
    background: #f5f7f8;
    height: 450px;
    overflow-y: auto;
}
.img-server {
    margin: 10px;
    cursor: pointer;
    border: 1px solid #ebebfa;
}
.img-server:hover {
    border: 3px solid #dedeff;
}

img.img-icon-cs {
    height: 80px !important;
    width: 80px !important;
    margin-top: 20px;
}
.list-item-text {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
}
.label-acc-infos {
    font-size: 18px;
    font-weight: bold;
}
.mobile-acc-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.service .list-item-text .item-del{
    width: 50%;
}
.service .list-item-text{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.put-in-right-cs {
    margin-top: -100px;
    margin-right: 15px !important;
}

.ht-500-cs {
    height: 500px;
}

span.mt-title {
    background: #969632;
    padding: 4px;
    border-radius: 8px;
    font-size: 16px;
}

.mb-tr-cs.amount {
    padding: 8px;
    font-size: 17px;
}

.mb-tr-cs {
    width: 50%;
    text-align: left !important;
}
.mt-10-cs {
    margin-top: 10px !important;
}
.dfs-cs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px;
    font-family: arial;
    font-size: 18px;
}
.bg-account {
    background-color: #3a324e;
}
.bg-dl-cs {
    background: #d8dfeb;
    margin: 0;
    padding: 8px 0;
}
.bg-tr-info {
    background-color: #506e8a !important;
}
.text-info-cs {
    color: #27748b;
}
.dl-btn-cs {
    display: flex !important;
    justify-content: flex-end;
    flex-direction: row;
    background: #f7fafb;
    height: 100px;
    align-items: flex-end !important;
}
.dl-tr-cs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background: #d8dfeb;
    padding: 6px 0 0 6px;
}

.info-tr {
    width: 50%;
}
.dl-tr-cs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background: #d8dfeb;
}

.content-tr-cs {
    background-color: #5c99ab;
    text-align: center;
    color: #eff2f1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    height: auto;
    padding: 10px;
    margin: -10px 0 20px 0;
}

.title-tr-cs {
    margin: 0;
    font-family: arial, "sans-serif";
}

.img-116-cs {
    height: 136px !important;
    width: 190px !important;
}

/*.h-116-cs {
    height: 216px;
}*/
.w-190-cs {
    width: 190px !important;
}
.pointer-cs {
    cursor: pointer;
}
.menu-header {
    background: #67d1c6;
    color: #f9f9f9 !important;
}
.start-flex-cs p {
    width: 30%;
}
.start-flex-cs {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}
.evenly-flex-cs p {
    width: 30%;
}
.evenly-flex-cs {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
.list-accounts {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.img-65 {
    width: 60px;
    height: 50px;
}
.mt-100-cs {
    margin-top: 100px;
}
.del-icon-cs {
    color: #bb8888;
    font-size: 30px;
    cursor: pointer;
}
.pd-15-cs {
    padding: 15px;
}
.mt-20-cs {
    margin-top: 20px;
}
.mt-50-cs {
    margin-top: 50px;
}
span.blueW {
    color: #1c7678;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
}

.h-125 {
    height: 125px;
}

.h-100 {
    height: 100px !important;
}

.w-150 {
    width: 150px !important;
}

.w-204 {
    width: 204px !important;
}

span.cblue {
    font-size: 11px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #638f91;
}
small.little-cs {
    color: white;
    background: #a2a2c5;
    border-radius: 4px;
    padding: 0 4px;
    letter-spacing: 0.8px;
    font-weight: 600;
    font-size: 12px;
}

main.main-content.loaded {
    overflow-x: auto !important;
}
.main-content-wrap {
    min-width: auto;
}
.nunito-ft {
    font-family: 'Nunito Sans', 'calibri', 'Roboto';
}
.bg-gray-i {
    background-color: #f1f1f1 !important;
}
.bar-top-gray {
    border-top: 2px solid #b7b5b5;
}
.dtl-transac {
    color: #424242;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 16px;
}
.fs-14 {
    font-size: 14px;
}
.subtitle {
    font-size: 18px;
    font-weight: 500;
    padding: 5px 15px;
    margin-bottom: 10px;
}

.bgc-gray {
    background: #e4e4e4;
}

.fwg-500 {
    font-weight: 500;
    background: orange;
}

.fs-16 {
    font-size: 16px;
}
.bg-94 {
    background: #94bcfb;
}

.bg-2e {
    background: #425569;
}

.wa0 {
    color: white;
    letter-spacing: 0.7px;
    font-family: 'Arial', sans-serif;
    font-size: 15px;
}

.btn-default .btn-text {
    white-space: normal;
}
span.btn-text {
    white-space: normal;
    font-size: 13px !important;
}

@media screen and (max-width: 1200px) {
    .grid-account {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width: 576px) {
    .grid-account, .grid-2col {
        grid-template-columns: 300px !important;
    }
}

.grid-4col {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
}

.grid-2col {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 30px;
    grid-row-gap: 15px;
}

.grid-account {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    grid-column-gap: 30px;
    grid-row-gap: 60px;
}

.account.opened {
    z-index: 9999;
}

.auth .form-title[title] {
    font-size: 25px !important;
    font-style: italic;
    color: #d6d6d6 !important;
}

.auth .content-header.title {
    color: #9db7f1;
    font-family: Arial;
    font-weight: bold;
    font-size: 32px;
}

.auth .link {
    color: #e2e2e2 !important;
    font-style: italic;
    font-size: 15px;
}

.auth .submit {
    /* background-image: linear-gradient(
250deg
, #cd5447, #b3589c 65%, #9d5ce5); */
    border: none;
    box-shadow: 5px 5px 20px 0 rgb(166 154 208 / 40%);
    color: #535e88 !important;
    background-color: #b8b5e8 !important;
    background-image: none !important;
}

.auth .error, .auth .error.ps-relative {
    color: #f39393;
}

.auth .form {
    background-color: white !important;
}

/* authentication content */
.auth .title-block, .auth .form-title {
    color: white !important;
    font-size: 1.8em;
    letter-spacing: 0.1px;
    margin: 0;
}

/* authentication content */
.auth.content-body {
    background: #43447b !important;
}

/* authentication body */
.auth.bg {
    background-image: linear-gradient(
            118deg, #d9d7e4, #fdfdfd) !important;
}

.account.shadow-none {
    background: #e0e0e0;
}

/*.account tr.data:hover .amount-col.data {
    background-color: #e0e0e0;
}*/
    /*.account tr.data:hover .amount-col.data {
    background-color: #d4d6da;
}*/

/* élément role dans le tableau */
.cuser.info-badge {
    background-color: #76a7ca !important;
}
.profilPane .card-content {
    /*background: #ececec;*/
}

.status.warning-badge {
    background-color: #e0984a !important;
}
.status.success-badge {
    background-color: #78bf87 !important;
}
.status.error-badge {
    background-color: #e47280 !important;
}
.acc-saved {
    background-color: #8f9ab3 !important;
    border: none;
    font-family: georgia, serif;
}

.account .amount-col[col].data {
    background-color: #d4d6da;
}

.account .amount-col[col] {
   /* background-color: #5d6679 !important;*/
}
.account td {
    font-family: 'Nunito Sans', sans-serif;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
    background-color: #a5adbf !important;
}
.account .status {
    font-weight: 600;
    font-family: 'Nunito Sans';
}
.account .amount {
    font-weight: 500;
}
.account tbody {
    background-color: #e0e0e0;
}
.account th {
    color: #f5f5f5 !important;
}
.account thead {
    /*background-color: #717c94;*/
    letter-spacing: 0.5px;
    font-family: arial;
    font-family: 'Nunito Sans';
}
.account .modal-header {
    background: #68748c;
    justify-content: center;
    flex-direction: row;
}

.account .title {
    color: #f1f1f1;
    font-size: 18px !important;
    font-family: 'Nunito Sans';
}

button.retour {
    background-color: #80b6de;
    border-color: inherit;
    font-family: georgia, serif;
}

.account-infos .data {
    font-size: 16px;
    font-family: arial;
    margin-top: 10px;
}

.account-infos.gradiantCard {
    background: #e6e6e6 !important;
}

.account-infos .content .column {
    color: #f1f1f1;
    font-family: georgia, serif;
}
.account-infos .content {
    background-color: #697286;
    padding: 3px 0;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.5px;
}

.header.info-badge {
    vertical-align: text-top;
    margin-left: 5px;
    font-weight: 400 !important;
}

.profilPane .card-title {
    color: #dcebec !important;
    font-family: arial;
}

/*.main-menu-wrap .menu-ul .menu-item.active:not(.has-sub) > .item-link {
    background: #5a93ff !important;
    color: white !important;
    font-weight: 500 !important;
}*/

.menu-home .card-title {
    letter-spacing: 0.5px !important;
    color: #555e6f !important;
}

.menu-home .card-title::first-letter {
    font-size: x-large;
}

.tc-badge.info-badge {
    font-weight: 500;
}
h1.page-title, .page-title, .page-title .card-title {
    color: #697180 !important;
}

.btn-text span.cash {
    font-size: xxx-large;
}

.profilPane .user-infos {
    color: #daeaff;
    font-family: arial;
    margin-top: 10px;
}

.profilPane .user-infos .title {
    color: white;
    font-style: italic;
}

.page-breadcrumbs span.link.last.ng-star-inserted {
    color: #226580 !important;
}
.page-breadcrumbs a.link.ng-star-inserted {
    color: #226580 !important;
}

/*.navbar-wrap {
    background: #224b69 !important;
}*/

.card-cash span.btn-text {
    font-size: 13px;
    font-family: arial;
    color: #434750;
    font-weight: bold;
}

span.btn-text {
    color: #191919;
}

/* 13 */
.btn-13[btn-13] {
    background-color: #fcfcfc;
    background-image: linear-gradient(315deg, #fcfcfc 0%, #ffffff 74%);
    border: 1px dashed #59a6cd;
    z-index: 1;
}
.btn-13[btn-13]:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #e6ebef;
    background-image: linear-gradient(315deg, #e2e4e8 0%, #e4e7ec 74%);
    box-shadow:
            -7px -7px 20px 0px #fff9,
            -4px -4px 5px 0px #fff9,
            7px 7px 20px 0px #0002,
            4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}
.btn-13[btn-13]:hover {
    color: #fff;
}
.btn-13[btn-13]:hover:after {
    top: 0;
    height: 100%;
}
.btn-13[btn-13]:active {
    top: 2px;
}
/* 13 end */

.card-cash .content {
    min-height: 400px;
}

.card-cash-min.card-cash .content {
    min-height: 216px;
}

.card-cash .btn-block {
    height: 100%;
}

.card-cash-min.card-cash .btn-block {
    height: 216px;
}

.card-cash .tc-card {
    height: 400px;
}

.button-content {
    background-color: whitesmoke;
    padding-top: 15px;
}

/* 4 START */
.btn-4[btn-4] {
    background-color: #4dccc6;
    background-image: linear-gradient(315deg, #4dccc6 0%, #03c8a8 74%);
    line-height: 42px;
    padding: 0;
    border: none;
}
.btn-4[btn-4]:hover{
    background-color: #89d8d3;
    background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.btn-4[btn-4] span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.btn-4[btn-4]:before,
.btn-4[btn-4]:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
    transition: all 0.3s ease;
}
.btn-4[btn-4]:before {
    height: 0%;
    width: .1px;
}
.btn-4[btn-4]:after {
    width: 0%;
    height: .1px;
}
.btn-4[btn-4]:hover:before {
    height: 100%;
}
.btn-4[btn-4]:hover:after {
    width: 100%;
}
.btn-4[btn-4] span:before,
.btn-4[btn-4] span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
    transition: all 0.3s ease;
}
.btn-4[btn-4] span:before {
    width: .1px;
    height: 0%;
}
.btn-4[btn-4] span:after {
    width: 0%;
    height: .1px;
}
.btn-4[btn-4] span:hover:before {
    height: 100%;
}
.btn-4[btn-4] span:hover:after {
    width: 100%;
}
/* 4 END*/
.custom-btn {
    min-width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}


.rt-code {
    background-color: #479680 !important;
    color: white !important;
}

/* les information de l'utilisateur
 après un retrait code */
.data-code {
    color: #767a82 !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
    font-size: 15px !important;
}
.data-label {
    font-size: 16px;
    font-style: italic;
    color: #5fad97;
    font-size: 15px !important;
}

.popup-container i.icofont-close-line {
    color: white;
    font-size: 20px;
}

.copy:hover, .copy:active {
    background-color: #86c16e !important;
    border-color: #258c2f !important;
    color: #fff !important;
}
.copy {
    border-color: #66d093 !important;
    color: #63caa4 !important;
}

.popup-container .title {
    text-align: center;
    width: 100%;
    color: #efe9e9;
    font-weight: normal !important;
    letter-spacing: 0.5px;
}

.tr-code {
    background-color: #8aa2c1;
    padding: 8px;
    text-align: center;
    width: 180px;
    color: #efe9e9;
    letter-spacing: 1px;
}

/* popup du template */
.popup-container .modal-window {
    background-color: #4b6479 !important;
    width: 600px !important;
}

.popup-container .card-title {
    color: #efe9e9 !important;
    letter-spacing: 0.5px !important;
    font-weight: normal !important;
}

/* les données du résultat d'un transfert code */
.tr-infos {
    color: #efe9e9;
    letter-spacing: 0.5px;
    font-style: italic;
}

.popup-container .tc-card {
    background-color: #5c7890;
}

/* modifie les propriétés scrollbar */
.form-content .tc-input-wrap {
    background-color: #eaeaea !important;
}

.mat-dialog-content .form-content {
    background-color: #f3f3f3;
}
.container-dialog .mat-dialog-title {
    padding: 5px 0;
}
/*.container-dialog .mat-dialog-title {
    background: #617a8e;
    color: #ececec;
    padding: 5px 0;
}*/
.little-gray-text {
    color: #4a505c;
}

@media screen and (min-width: 1084px) {
    .mw-md-form-min {
        min-width: 600px;
    }
    .mw-md-form-medium {
        min-width: 800px;
    }
}

/* on affiche en colonne à cette taille, parce qu'on avait
donné une taille fixe */
@media screen and (max-width: 1083px) {
    .btn-medium-column {
        flex-direction: column !important;
        max-width: 100%;
    }
}

@media screen and (min-width: 576px) {
    .btn-cs-row {
        flex-direction: row !important;
    }
}

.input-infos {
    border: none;
    cursor: text;
}

.tc-modal.popup-container {
    z-index: 9999;
}

.not-important.error {
    color: #e6707e;
    font-size: inherit;
}

::-webkit-scrollbar {
    width: 14px !important;
    height: 14px !important;
}

/* modifie les propriétés scrollbar des tables */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5c9cf !important;
}

/* modifie les propriétés scrollbar des tables */
nz-table ::-webkit-scrollbar {
    width: 10px;
    height: 10px !important;
    background: aliceblue;
}
nz-table ::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* pour les messages de succès */
.transaction.success {
    font-size: 0.85em;
    font-weight: bold;
    color: #2c860f;
}
.success.ps-relative {
    top: 0 !important;
}

.success-cs, div.success-cs {
    color: white;
    background-color: #5a9a5a;
    padding: 10px;
    border-radius: 3px;
    font-family: arial;
    font-weight: bold;
}

.content-header.title {
    color: wheat;
    font-family: Arial;
    font-weight: bold;
    font-size: 20px;
}

.alert-cs.error, tc-alert.alert-cs.error {
    background: rgb(44, 108, 102);
    color: #ffffff;
    border-color: #2fa7ff;
    font-family: 'Roboto';
    background-color: #b15454;
}

.alert-cs.success, tc-alert.alert-cs.success {
    background: rgb(44, 108, 102);
    color: #ffffff;
    border-color: #2fa7ff;
    font-family: 'Roboto';
    background-color: #21ae80;
}
.animated.toofaster {
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
}
.ligth-cs {
    color: #4a505c !important;
}
.absolute-center {
    position: absolute !important;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 500px !important;
}
.cdk-overlay-container {
    z-index: 9999 !important;
}
.ps-relative {
    position: relative !important;
}

.error.ps-relative {
    top: 0 !important;
    color: #f36666 !important;
}
.error-cs {
    color: #e05d6d;
}

.btn-default.cs-valid {
    background: #2193b0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);  /* Chrome 10-25,
    Safari 5.1-6 */
    background: linear-gradient(to right, #6dd5ed, #2193b0); /* W3C, IE 10+/ Edge,
    Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white !important;
}

.btn-default.cancel:hover .btn-text {
    color: #2fa7ff;
}

.shadow-none, tc-card.shadow-none {
    box-shadow: none;
}
